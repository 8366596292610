import React from 'react'
import { Container } from 'react-bootstrap';
function Footer() {
  return (
    <div className='bg-white mt-3 border-top'>
       <Container>
         <div className="col-md-12 bg-light pb-2 pt-2">
        <div className="row m-0" >
          <div className="col-lg-12 ">
            <p className="small mb-4 mb-lg-0 pt-2 text-center">Copyright © 2024 Employment Exchange, LLC. or its affiliates. All rights reserved.</p>
          </div>
          
          </div>
      </div>
      </Container>
    </div>
  )
}

export default Footer