import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "./Assets/EmploymentExchange_Logo.svg";
import Col from "react-bootstrap/Col";
import Authuser from "./Authuser";
function ForgotPassword() {
  const { http, getUser } = Authuser();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const validateEmail = () => {
    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Validate email
    if (!email.trim()) {
      setError("Email required");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setError("Email not valid");
      isValid = false;
    } else {
      setError();
    }
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail()) {
      http
        .post(`/timesheet/forgotpassword`, { email: email })
        .then((response) => {
          //setToken(response.data.userdetail,response.data.token);
          //console.log(response.status);
          console.log(response.data);
          setSuccessMessage(response.data.message);
          setEmail("");
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          //console.log(error.response.data.error);
          setError(error.response.data.error);

          //setsubmitted(false)
        });
    } else {
      // Form is not valid, display error messages
    }
  };
  return (
    <Container fluid className="Regbg pt-4 pb-4">
      <Row className="min-vh-75">
        <Card className="rounded-0" style={{ width: "25rem", margin: "auto" }}>
          <Card.Body>
            <Row>
              <Col lg={12}>
                <a href="/"><img src={Logo} alt="Profile" className="img-fluid p-2 " /></a>
                <h6 className="text-center fw-bold">Forgot Password</h6>
                <Form className="mt-3" method="POST" onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      className="rounded-0 p-3 shadow-sm"
                      placeholder="Email"
                    />
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback small"
                    >
                      {error}
                    </div>
                    <div
                      style={{ display: "block" }}
                      className="alert-success success small"
                    >
                      {successMessage}
                    </div>
                  </Form.Group>
                  <div className="d-grid gap-2">
                    <Button
                      type="submit"
                      variant="primary"
                      size="sm"
                      style={{ backgroundColor: "#2F9DCC" }}
                      className="rounded-0 fw-bold border-0 shadow"
                    >
                      {" "}
                      Submit{" "}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
