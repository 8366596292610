import React,{useState,useEffect} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-tagsinput/react-tagsinput.css';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { EditText, EditTextarea } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import Authuser from './Authuser';
function Profile() {
  const{http,getUser,setUser}=Authuser();
  let userdetails = getUser();
  let formattedDate;
  const date = new Date(userdetails.created_at);

        // Format the date as "Dec 29, 2023"
        formattedDate = date.toLocaleDateString('en-US', {
          month: 'short', // "Dec"
          day: 'numeric', // "29"
          year: 'numeric', // "2023"
        });
  const handleSave = ({ name, value }) => {
    console.log(value);
    console.log(name);
    http.post(`/timesheet/updateprofile/${userdetails.email}`,{fieldname:name,updatevalue:value}).then((response) =>{
      //setProfiledata(response.data.userprofile)
      setUser(response.data.userdata)
    }).catch(function (error){
      console.log(error);
    });
  };
   return (
    <Container fluid>
      <br/><br/>
      <Card style={{ width: '33rem' }} className='m-auto rounded-0'>     
        <Card.Body>
          <Card.Text >
            <Row>
              <Col>
                <div class="box-top">                
                  <div class="profile">                      
                      <div class="profile-img">
                          <img src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-256.png" />
                      </div>                       
                      <div class="name-user">
                          <strong>Transnational Software Services Inc</strong>
                          <span>Manager </span>
                      </div>
                  </div>
                </div>            
              </Col>           
            </Row>
            <Row>          
              <Col>
                <Table striped bordered hover>    
                  <tbody>
                    <tr>
                      <td className='fw-bold'>Name</td>
                      <td>
                        <EditText name='firstname' onSave={handleSave} defaultValue={userdetails.firstname} style={{width:375}} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton />
                      </td>                    
                    </tr>
                    <tr>
                      <td className='fw-bold'>Member since</td>
                      <td>
                        <EditText name='created_at' defaultValue={formattedDate} style={{width:375}} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton readonly/>
                      </td>
                    </tr>
                    <tr>
                      <td className='fw-bold'> Email </td>
                      <td>
                        <EditText name='email' defaultValue={userdetails.email} style={{width:375}} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton readonly />
                      </td>                    
                    </tr>
                    <tr>
                      <td className='fw-bold'>Contact No </td>
                      <td>
                      <EditText name='contactno' defaultValue={userdetails.contactno} style={{width:375}} editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton onSave={handleSave}/>
                      </td>
                      
                    </tr>
                    <tr>
                      <td className='fw-bold'>Location</td>
                      <td>
                        <EditText name='location' defaultValue={userdetails.location}  editButtonProps={{ style: { marginLeft: '5px', width: 16 } }} showEditButton onSave={handleSave}/>
                      </td>
          
                    </tr>     
                  </tbody>
                </Table>
              </Col>
              <Col></Col>
            </Row>          
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>

    );
}

export default Profile
