import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import InputGroup from 'react-bootstrap/InputGroup';
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Logo from "./Assets/EmploymentExchange_Logo.svg";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Authuser from './Authuser';
import { useNavigate } from 'react-router-dom'
function Resetpassword() {
    const [password, setPassword] = useState("Abc.@678");
    const [values, setValues] = useState({ password: "", confpassword: "" });
    const [errors, setErrors] = useState({ password: "", confpassword: "", message: ""});
    const {http} = Authuser();
    const {token,email} = useParams();
    const [submitted, setsubmitted] = useState(false);
    const [message, setMessage] = useState('');
    const[status,setStatus]=useState(false);
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState("fa-solid fa-eye-slash");
    const navigate = useNavigate();
    const show = () => {
      type === "password" ? setType("text") : setType("password");
      icon === "fa-solid fa-eye" ? setIcon("fa-solid fa-eye-slash") : setIcon("fa-solid fa-eye");
    };
    const validateForm = () => {
        let isValid = true;
        const newErrors = { ...errors };
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        // Validate email
        if (!values.password.trim()) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else {
            newErrors.password = '';
        }

        // Validate password
        if (!values.confpassword.trim()) {
            newErrors.confpassword = 'Password is required';
            isValid = false;
        } else {
            newErrors.confpassword = '';
        }

        setErrors(newErrors);
        return isValid;
    };
  const handlePassword = (event) => {
      let new_pass = event.target.value;
      let new_name = event.target.name;
      
      setPassword(new_pass);
      setValues({...values,[new_name]:event.target.value})
      const newErrors = { ...errors };
      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
          newErrors.new_name = "Password should contains lowercase letters!";
      } else if (!new_pass.match(upperCase)) {
          newErrors.new_name = "Password should contain uppercase letters!";
      } else if (!new_pass.match(numbers)) {
          newErrors.new_name = "Password should contains numbers also!";
      } else if (new_pass.length < 8) {
          newErrors.new_name = "Password length should be more than 8.";
      } else {
          newErrors.new_name='';
      }
  }
  const handleSubmit = async (e) =>{
      e.preventDefault();
      //validation(values);
      let password = values.password;
      let confpassword = values.confpassword;        
      let token = e.target.token.value;
      let email = e.target.email.value;
      const newErrors = { ...errors };
      if (validateForm()) {
          //if(props.pageview === 'Create Password'){
          //    var body = {pswd:password,confirm_password:confpassword,email:email};
          //}else{
              var body = {pswd:password,confirm_password:confpassword,token:token,email:email};
          //}
        http.post(`/timesheet/passwordUpdate`,body).then((response)=>{
            //setToken(response.data.userdetail,response.data.token);
            //console.log(response.status);
            console.log(response.data);
            setMessage('Password created successfully!');
            setStatus(true)
            
        }).catch(function (error) {
          // handle error
          console.log(error);
          //console.log(error.response.data.error);
          
          if(error?.response?.status==403){
            newErrors.password = error.response.data?.error?.pswd;
            newErrors.confpassword = error.response.data?.error?.confirm_password;
            setErrors(newErrors);
          }
          if(error.response.status==401){
            newErrors.message = error.response.data?.error;
            setErrors(newErrors);
          }
          
          //setsubmitted(false)
        })
      } else {
        // Form is not valid, display error messages

      }
  }
  return (
    <Container fluid className="Regbg pt-4 pb-4">
      <Row className="min-vh-75">
        <Card className="rounded-0" style={{ width: "25rem", margin: "auto" }}>
          <Card.Body>
            <Row>
              <Col lg={12}>
                <img src={Logo} alt="Profile" className="img-fluid p-2 " />
                {status === true ?
            
                <div className='row'>
                    <div className="alert-success success small">{message}</div>
                    <p>Click here to <a href="/">login</a></p>
                </div>:
                <>
                  <h6 className="text-center fw-bold">
                    <i className="bi bi-person-fill"></i> Reset Password
                  </h6>
                  
                  <Form className="mt-3" method="post" onSubmit={handleSubmit}>
                    <div class="mb-2 col-md-12">
                      <Form.Control type={type} name="password" className="rounded-0 p-3 shadow-sm" placeholder="Password" onChange={handlePassword} />
                      <i onClick={show} className={icon} style={{position: "absolute",right: "35px",bottom: "125px",}}></i>     
                      <span style={{display:'block', color: "#bc3f34" }}className="invalid-feedback">{errors.password}</span>     
                    </div>
                    <input type="hidden" name="token" value={token} />
                    <input type="hidden" name="email" value={email} />
                    <div class="mb-2 col-md-12">
                      <Form.Control type={type} name="confpassword" className="rounded-0 p-3 shadow-sm" placeholder="Re type password" onChange={handlePassword} />
                      <i onClick={show} className={icon} style={{position: "absolute",right: "35px",bottom: "125px",}}></i>     
                      <span style={{display:'block', color: "#bc3f34" }}className="invalid-feedback">{errors.confpassword}</span>  
                    </div>
                    <span style={{display:'block', color: "#1cc88a"  }} className="alert-success success small">{message}</span>
                    <div className="d-grid gap-2">
                      <Button variant="primary" type="submit" size="sm" style={{ backgroundColor: "#2F9DCC" }} className="rounded-0 fw-bold border-0 shadow" onClick={validateForm} disabled={submitted} 
                      >
                        {" "}Update{" "}
                      </Button>
                    </div>
                  </Form>
                </> 
                }
                
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default Resetpassword