import React,{useState} from 'react'
import {Dropdown,DropdownButton,ButtonGroup,} from "react-bootstrap";
import Authuser from "../Authuser";
import Viewdocument from './Viewdocument';
function Actions(props) {
  const{http,getUser} = Authuser();
  let userdetails = getUser();
  const {data} = props;
  const [modalshow, setModalshow] = useState(false);
  const [file, setFile] = useState(false);
  const handleModalShow = (filename) =>{
      console.log(filename)
      setModalshow(true);
      setFile(filename);
  } 
  const handleStatus = (val, id) =>{
      
      http.get(`/timesheet/chageTimesheet/${userdetails.email}?ticketid=${id}&statusval=${val}&userId=${userdetails.id}`)
      .then((response) => {
          //fetchItems();
          props.refreshData();
      })
      .catch(function (error) {
          // handle error
          console.log(error.response.data.error);
      });
  }
  const handleDownload = (filename) =>{
      //console.log(filename);
      http.post(`/timesheet/timesheetdownload/${userdetails.email}`,{file:filename}, {
          responseType: 'blob',
      })
      .then((response) => {
          //console.log(response);
          //let filename = filename;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url; //''
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
      .catch(function (error) {
          // handle error
          console.log('There was an error downloading the file!', error);
      });
  }
  return (
    <>
      <DropdownButton  as={ButtonGroup} size="sm" autoClose="outside" variant="muted" style={{ maxHeight: "28px" }} 
        title={
          <span>
            <i class="bi bi-three-dots"></i>
          </span>
        }>
              
        <Dropdown.Item href="#" onClick={()=>handleDownload(data.file_name)}>Download</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleModalShow(data.file_name)}>View</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleStatus('A',data.id)} className="text-success">Approve</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleStatus('R',data.id)} className="text-danger">Reject</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleStatus('D',data.id)} className="text-danger">Dispute</Dropdown.Item>
        <Dropdown.Item href="#" onClick={()=>handleStatus('DL',data.id)} className="text-danger">Delete</Dropdown.Item>                                                                  
      </DropdownButton>
      <Viewdocument show={modalshow} filename={file} foldertype="empselftimesheets" close={() => setModalshow(false)}/>
    </>
    
  )
}

export default Actions
