import React,{useState} from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import { CardLink } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import Authuser from './Authuser';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: '',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];


export const Quarterly = {
  labels: [
    ["Q1 Jan - Mar"],
    ["Q2 Apr - Jun"],
    ["Q3 Jul - Sep"],
    ["Q4 Oct - Dec"],

  ],
  datasets: [
    {
      label: 'Total Hours',
      data: [256, 100, 35, 60],
      backgroundColor: [
        'rgba(48, 192, 205)',
        'rgba(39, 69, 104)',
        'rgba(248, 180, 21)',
        'rgba(103, 145, 36)',

      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(54, 162, 235, 1)',

      ],
      borderWidth: 1,
    },
  ],
};

export const data = {
  labels: ['Today', 'Weekly'],
  datasets: [
    {
      label: 'Total Hours',
      data: [256, 100],
      backgroundColor: [
        'rgba(48, 192, 205)',
        'rgba(39, 69, 104)',

      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',


      ],
      borderWidth: 1,
    },
  ],
};

export const Monthly = {
  labels: [
    ["JAN"],
    ["FEB"],
    ["MAR"],
    ["APR"],
    ["MAY"],
    ["JUN"],
    ["JUL"],
    ["AUG"],
    ["SEP"],
    ["OCT"],
    ["NOV"],
    ["DEC"],
  ],
  datasets: [
    {
      label: 'Total Hours',
      data: '',
      backgroundColor: 'rgb(126, 165, 63)',
    },

  ],
};

export const History = {
  labels: [
    ["2019"],
    ["2020"],
    ["2021"],
    ["2022"],
    ["2023"],

  ],
  datasets: [
    {
      label: 'Total Hours',
      data: '',
      backgroundColor: 'rgb(38, 133, 198)',
    },


  ],
};





function Dashboard() {
  const { http, getUser } = Authuser();
  let userdetails = getUser();
  const [months,setMonths]=useState([
    {
        monthno: '01',
        monthname: "Jan"
    },
    {
        monthno: '02',
        monthname: "Feb"
    },
    {
        monthno: '03',
        monthname: "Mar"
    },
    {
        monthno: '04',
        monthname: "Apr"
    },
    {
        monthno: '05',
        monthname: "May"
    },
    {
        monthno: '06',
        monthname: "Jun"
    },
    {
        monthno: '07',
        monthname: "Jul"
    },
    {
        monthno: '08',
        monthname: "Aug"
    },
    {
        monthno: '09',
        monthname: "Sep"
    },
    {
        monthno: '10',
        monthname: "Oct"
    },
    {
        monthno: '11',
        monthname: "Nov"
    },
    {
        monthno: '12',
        monthname: "Dec"
    }]);
  const now = new Date();
  const current = now.getFullYear();
  const previous = current - 1;
   // Download file
  const handleDownload = (month) => {
      if(month == current || month == previous){           
          var apiurl = `/timesheet/downloadtimesheet/${userdetails.email}?year=${month}`;
      }else{
          var apiurl = `/timesheet/downloadtimesheet/${userdetails.email}?id=${month}`;
      }
    http.get(apiurl, {
      responseType: 'blob',
    })
    .then((response) => {
        //console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url; //''
        link.setAttribute('download', 'download.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
    .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
    });

  };
  return (
    <Container fluid>
      <Breadcrumb className='mt-2'>
        <Breadcrumb.Item as={Link} to="/Dashboard">Dashboard</Breadcrumb.Item>
      
      
      </Breadcrumb>

      <Container>
        <Row className='m-1'>
          <Col sm={4} className='d-flex'>
            <h6>Dashboard </h6>
          </Col>
          <Col sm={4} className='pb-2'>

          </Col>
          <Col sm={4} >
            <div class="dropdown float-end">
              
            </div>
          </Col>
          <Col sm={8}>
            <div class="card rounded-0" >

              <div class="card-body">
              <h5 class="card-title">Monthly</h5>
                <Bar options={options} data={Monthly} />
              </div>
            </div>

            <Row className='mt-2'>
              <Col sm={6}>
              <div class="card rounded-0" >

<div class="card-body">
  <h5 class="card-title">History</h5>
  <Bar options={options} data={History} />
</div>
</div>

              </Col>
              <Col sm={6}>
                <div class="card rounded-0" >

                  <div class="card-body">
                  <h5 class="card-title">Quarterly</h5>
                    <Pie data={Quarterly} />
                  </div>
                </div>
              </Col>
            </Row>
          

          </Col>
          <Col sm={4}>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Download Timesheets</th>
                  <th></th>
                </tr>
              </thead>
           
              <thead>
                <tr>
                  <th>Monthly</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                    months.map(month => (
                      <tr key={month.monthno}>
                        <td>{month.monthname}</td>
                        <td> <CardLink as={Link} to={`/Timesheets/${month.monthno}`}>View </CardLink> | <CardLink href="#" onClick={() => handleDownload(month.monthno)} > Download	</CardLink> </td>
                      </tr>
                    ))
                }
                
              </tbody>
              <thead>
                <tr>
                  <th>Quarterly</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Q1</td>
                  <td> <a href="/Timesheets/01,02,03">View </a> | <a href="#" onClick={() => handleDownload('01,02,03')}> Download	</a> </td>
                </tr>
                <tr>
                  <td>Q2</td>
                  <td> <a href="/Timesheets/04,05,06">View </a> | <a href="#" onClick={() => handleDownload('04,05,06')}> Download	</a> </td>
                </tr>
                <tr>
                  <td>Q3</td>
                  <td> <a href="/Timesheets/07,08,09">View </a> | <a href="#" onClick={() => handleDownload('07,08,09')}> Download	</a> </td>
                </tr>
                <tr>
                  <td>Q4</td>
                  <td> <a href="/Timesheets/10,11,12">View </a> | <a href="#" onClick={() => handleDownload('10,11,12')}> Download	</a> </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>History</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2022</td>
                  <td> <a href="/Timesheets/2022">View </a> | <a href="#" onClick={() => handleDownload('2022')}>Download	</a> </td>
                </tr>
                <tr>
                  <td>2023</td>
                  <td> <a href="/Timesheets/2023">View </a> | <a href="#" onClick={() => handleDownload(previous)}>Download</a> </td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th>Current Year 2024</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Year to Date</td>
                  <td> <a href={`/Timesheets/${current}`}>View </a> | <a href="#" onClick={() => handleDownload(current)}> Download	</a> </td>
                </tr>
              </tbody>
            </Table>
           
          </Col>
        </Row>
      </Container>
    </Container>
    
  );
}
export default Dashboard