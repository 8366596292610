import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/EmploymentExchange_Logo.svg';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Routes,Route } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Changepassword from '../Changepassword';
import Timesheets from '../Timesheets';
import Profile from '../Profile';
import Authuser from '../Authuser';
function Header() {
  const {token,logout,getUser} = Authuser();
  let userdetails = getUser();
  const logoutUser = () =>{
    if(token !== undefined){
      logout();
    }
  }
  const isAuthenticated = (userdetails !== '') ? true : false;
  return (
    <div > 
      <nav class="navbar navbar-expand-lg" style={{backgroundColor:"#ffffff"}}>
        <div class="container-fluid">
        <Navbar.Brand  as={Link} to="/"><img src={logo} alt='Employment Exchange' className='img-fluid logo' style={{width:320}} /></Navbar.Brand>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">      
            <ul class="navbar-nav ms-md-auto gap-2 list-group-horizontal">
              <li class="nav-item dropdown  pt-2 ml-2" style={{borderLeft:'1px solid #D5D5D5'}}>
                <Nav.Link class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-caret-down-fill"></i></Nav.Link>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  <Dropdown.Item><span className='fw-bold'>{userdetails?.firstname} {userdetails?.lastname}</span></Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Profile">My Profile</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Changepassword">Change Password</Dropdown.Item>
                  <Dropdown.Item onClick={logoutUser} >Logout</Dropdown.Item>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path="/" exact element={<Dashboard />}/>
        <Route path="/Profile" exact element={<Profile />}/>
        <Route path="/Changepassword" exact element={<Changepassword />}/>
        <Route path="/Timesheets/:id" exact element={<Timesheets />}/>
      </Routes>
    </div>
  )
}

export default Header