import React,{ useState,useEffect,useMemo } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Pdf from "./Components/Pdf.jsx";
import Actions from "./Components/Actions.jsx";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { useParams } from 'react-router-dom';
import Authuser from './Authuser.jsx';
import Spinner from 'react-bootstrap/Spinner';

function Timesheets() {
    const now = new Date();
    const current = now.getFullYear();
    const previous = current - 1;
    const old = current - 2;
    // Row Data: The data to be displayed.
    const [rowData, setRowData] = useState([]);
    const [months,setMonths]=useState({'01': "Jan",'02': "Feb",'03': "Mar",'04': "Apr",'05': "May",'06': "Jun",
                                        '07': "Jul",'08': "Aug",'09': "Sep",'10': "Oct",'11': "Nov",'12': "Dec",
                                        "01,02,03" : "Q1 Jan - Mar","04,05,06" : "Q2 Apr - Jun","07,08,09" : "Q3 Jul - Sep","10,11,12" : "Q4 Oct - Dec"
                                    });
    //    console.log(months[10])
    function fetchItems(){
        if(id == current || id == previous || id == old){           
            var url = `/timesheet/timesheetreport/${userdetails.email}?year=${id}`;
        }else{
            var url = `/timesheet/timesheetreport/${userdetails.email}?id=${id}`;
        }
        http.get(url)
        .then((response) => {
            console.log(response.data)
            setRowData(response.data.timesheetreports);
            setReportof(response.data.reportof);
            setYear(response.data.year);
        })
        .catch(function (error) {
            // handle error
            console.log(error.response.data.error);
        });
    }
    useEffect(() => {
        fetchItems();
        
    }, []);
    const refreshData = () =>{
        fetchItems();
    }
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
    {headerName: "EmployeeName",field:"firstname",
        
        cellRenderer: (params) => {
            return (
             <span>{params.data.firstname} {params.data.lastname}</span>
            );
            
        },
        
        
        //valueGetter: (params) => `${params.data.firstname} ${params.data.lastname}`,
        //cellRendererFramework: Actions,
    },
    { headerName: "ProjectType",field:"type"},
    { headerName: "Month",field:"month",
        cellRenderer: (params) => {
            return (
             <span>{months[params.data.month]}</span>
            );
        } },
    { headerName: "Year",field:"year" },
    { headerName: "WorkingHours",field:"workhrs" },
    { headerName: "Attachment",field:"file_name",
        cellRenderer: Pdf,
            
        },

    { headerName: "Actions",
        cellRenderer: Actions,
        cellRendererParams: {
            refreshData: refreshData // Pass folder name or other data as custom params
        },
        cellStyle: { overflow: "visible", zIndex: "auto" },// Set this line
        resizable: true,
        suppressMenu: true,
        minWidth: 100,
        width: 80 
    },
    { headerName:"Status", field: "status" },
    { headerName: "SubmittedDate",field:"created_at" },

    ]);
    // ...
    const defaultColDef = useMemo(() => {
    return {
        flex: 1,
        filter: true,
    }

    });
    const {id} = useParams();
    const { http, getUser } = Authuser();
    let userdetails = getUser();
    const [reportof,setReportof]=useState('');
    const [year,setYear]=useState('');
    
    
     // Download file
    const handleDownload = (month) => {
        if(month == current || month == previous){           
            var apiurl = `/timesheet/downloadtimesheet/${userdetails.email}?year=${month}`;
        }else{
            var apiurl = `/timesheet/downloadtimesheet/${userdetails.email}?id=${month}`;
        }
    http.get(apiurl, {
        responseType: 'blob',
    })
    .then((response) => {
        //console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url; //''
        link.setAttribute('download', 'Download.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
    .catch(function (error) {
        // handle error
        console.log(error.response.data.error);
    });
    
    };
   return (
        <Container fluid>
          
            <Breadcrumb className='mt-2'>
                <Breadcrumb.Item as={Link} to="/">Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item href="#"> Timesheets </Breadcrumb.Item>
         
            </Breadcrumb>
            <Row className='m-1'>
                <Col sm={4} className='d-flex'>
                    <h6>Timesheets | {(id == current || id == previous || id == old) ? id : months[id]+" "+current}</h6>
                </Col>
                <Col sm={4} className='pb-2'>
                </Col>
                <Col sm={4} className="d-flex justify-content-end">
                <Button variant="" type="submit" onClick={() => handleDownload(id) }className='rounded-0 text-white' style={{backgroundColor:'#2F9DCC'}}><i class="bi bi-box-arrow-down"></i> Download</Button>
                </Col>
                <div className="ag-theme-quartz" // applying the grid theme
                style={{ height: 550 }} // the grid will fill the size of the parent container
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        pagination={true}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20]}
                        defaultColDef={defaultColDef}
                       
                    />
                    </div>
           </Row>
 </Container>

    );
}

export default Timesheets